<template>
  <AdminView>
    
  </AdminView>
</template>

<script>
import AdminView from '@/components/AdminView'

export default {
  name: 'DataBaseHome',
  components: {
    AdminView
  }
}
</script>
